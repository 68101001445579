import React from "react";
import AthleteRosterItem from "./AthleteRosterItem";
import EmptyRosterItem from "./EmptyRosterItem";
import { Box } from "@mui/system";

const RosterItem = ({ athlete, onClick }) => {
  return (
    <Box onClick={onClick}>
      {athlete ? <AthleteRosterItem athlete={athlete} /> : <EmptyRosterItem />}
    </Box>
  );
};

export default RosterItem;
