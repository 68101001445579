import { Outlet, Link } from "react-router-dom";
import { Stack, Divider, Paper } from "@mui/material";

const Layout = () => {
  return (
    <>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Paper>
          <Link to="roster">Je team</Link>
        </Paper>
        <Paper>
          <Link to="leaderboards">Tussenstand</Link>
        </Paper>
      </Stack>
      <Outlet />
    </>
  );
};

export default Layout;
