import { Dialog, List, ListItem } from "@mui/material";
import { useListAthletesQuery } from "../api/athletesApi";

const SelectAthletesModal = ({ division, onSelect, open, onClose }) => {
  const { data: availableAthletes, isLoading: athletesIsLoading } =
    useListAthletesQuery({ division });

  return (
    <Dialog open={open} onClose={onClose}>
      {athletesIsLoading ? (
        <span>Loading</span>
      ) : (
        <List>
          {availableAthletes.map((athlete, index) => {
            return (
              <ListItem
                button
                onClick={() => onSelect(athlete)}
                key={`a${index}`}
              >
                {athlete}
              </ListItem>
            );
          })}
        </List>
      )}
    </Dialog>
  );
};

export default SelectAthletesModal;
