import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { athletesApi } from "./api/athletesApi";
import { leaderboardsApi } from "./api/leaderboardsApi";
import { rosterApi } from "./api/rosterApi";

export const store = configureStore({
  reducer: {
    [athletesApi.reducerPath]: athletesApi.reducer,
    [rosterApi.reducerPath]: rosterApi.reducer,
    [leaderboardsApi.reducerPath]: leaderboardsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(athletesApi.middleware)
      .concat(rosterApi.middleware)
      .concat(leaderboardsApi.middleware),
});

setupListeners(store.dispatch);
