import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareAuthHeaders } from "./prepareAuthHeaders";

export const athletesApi = createApi({
  reducerPath: "athletesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    listAthletes: builder.query({
      query: ({ division }) => `ListAthletes?year=2023&division=${division}`,
    }),
  }),
});

export const { useListAthletesQuery } = athletesApi;
