import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../authConfig";

export const prepareAuthHeaders = async (headers) => {
  const msalInstance = new PublicClientApplication(msalConfig);
  const account = msalInstance.getActiveAccount();

  const token = await msalInstance
    .acquireTokenSilent({ ...loginRequest, account })
    .catch(() => {
      msalInstance.loginRedirect(loginRequest);
    });
  if (token.accessToken) {
    headers.set("Authorization", `Bearer ${token.accessToken}`);
  }

  return headers;
};
