import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareAuthHeaders } from "./prepareAuthHeaders";

export const leaderboardsApi = createApi({
  reducerPath: "leaderboardsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    getLeaderboard: builder.query({
      query: () => `GetLeaderboard?year=2023`,
    }),
  }),
});

export const { useGetLeaderboardQuery } = leaderboardsApi;
