import React from "react";

const AthleteRosterItem = ({ athlete }) => {
  return (
    <>
      <span>{athlete}</span>
    </>
  );
};

export default AthleteRosterItem;
