import Layout from "./pages/Layout";
import RosterPage from "./pages/RosterPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    if (!isAuthenticated) {
      instance.loginRedirect(loginRequest);
    }

    instance.handleRedirectPromise().then((response) => {
      if (response) {
        instance.setActiveAccount(response.account);
      }
    });
  }, [instance, isAuthenticated]);

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/roster" element={<RosterPage />} />
              <Route path="/leaderboards" element={<LeaderboardPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>
    </div>
  );
}

export default App;
