import React, { useState } from "react";
import RosterItem from "./RosterItem";
import { useChangeInRosterMutation, useGetRosterQuery } from "../api/rosterApi";
import "./Roster.css";
import { useMsal } from "@azure/msal-react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
} from "@mui/material";
import SelectAthletesModal from "./SelectAthletesModal";

const Roster = ({ division }) => {
  const msal = useMsal();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [indexToChange, setIndexToChange] = useState(null);

  const {
    data: currentRoster,
    error: rosterError,
    isLoading: rosterIsLoading,
  } = useGetRosterQuery({ user: msal.accounts[0].username, division });

  const [changeInRoster] = useChangeInRosterMutation();

  const handleOnSelectAthlete = (index) => {
    setDialogIsOpen(true);
    setIndexToChange(index);
  };

  const handleOnAthleteSelected = (athlete) => {
    changeInRoster({
      index: indexToChange,
      athlete,
      user: msal.accounts[0].username,
      division,
      year: 2023,
    });

    setDialogIsOpen(false);
    setIndexToChange(null);
  };

  if (rosterIsLoading) return "Loading...";

  if (rosterError) return "error";

  return (
    <Box
      sx={{
        width: 500,
        height: 400,
      }}
    >
      <SelectAthletesModal
        division={division}
        open={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        onSelect={(athlete) => handleOnAthleteSelected(athlete)}
      />
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Captain</strong>
              </TableCell>
              <TableCell>
                <RosterItem
                  athlete={currentRoster[0]}
                  onClick={() => handleOnSelectAthlete(0)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Co-Captain</strong>
              </TableCell>
              <TableCell>
                <RosterItem
                  athlete={currentRoster[1]}
                  onClick={() => handleOnSelectAthlete(1)}
                />
              </TableCell>
            </TableRow>
            {[...currentRoster.slice(2, 5)].map((a, i) => {
              return (
                <TableRow key={`squad${i}`}>
                  <TableCell>{i === 0 && <strong>Squad</strong>}</TableCell>
                  <TableCell>
                    <RosterItem
                      athlete={a}
                      onClick={() => handleOnSelectAthlete(i + 2)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Roster;
