import { useGetLeaderboardQuery } from "../api/leaderboardsApi";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const LeaderboardPage = () => {
  const {
    data: leaderboard,
    error: leaderboardError,
    isLoading: leaderboardIsLoading,
  } = useGetLeaderboardQuery();

  if (leaderboardIsLoading) return <div>Loading</div>;
  if (leaderboardError) return <div>Cannot load leaderboard right now.</div>;

  return (
    <div>
      <h1>Leaderboard</h1>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Naam</TableCell>
            <TableCell>Punten</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaderboard.map((l, i) => {
            return (
              <TableRow>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{l.name}</TableCell>
                <TableCell>{l.points}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default LeaderboardPage;
