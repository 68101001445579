import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareAuthHeaders } from "./prepareAuthHeaders";

export const rosterApi = createApi({
  reducerPath: "rosterApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    getRoster: builder.query({
      query: ({ user, division }) =>
        `GetRoster?user=${user}&division=${division}&year=2023`,
      providesTags: () => [{ type: "Roster", id: "GET" }],
      transformResponse: (response) => {
        return response && response.length > 0 ? response : new Array(5);
      },
    }),
    changeInRoster: builder.mutation({
      query: ({ ...body }) => ({
        url: `ChangeInRoster`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: [{ type: "Roster", id: "GET" }],
    }),
  }),
});

export const {
  useListAthletesQuery,
  useGetRosterQuery,
  useAddToRosterMutation,
  useRemoveFromRosterMutation,
  useChangeInRosterMutation,
} = rosterApi;
